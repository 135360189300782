const burgerBtn = document.querySelector('.nav__hamburger-btn')
const navMobile = document.querySelector('.nav__mobile-links')
const mobileNavLinks = document.querySelectorAll('.nav__mobile-link')
const year = document.querySelector('.footer__year')
const msgStatus = document.querySelector('.contact__msg-status')
const contactForm = document.querySelector('.contact__form')

const handleMenu = () => {
	if (navMobile.classList.contains('active') !== true) {
		navMobile.style.right = '0px'
	} else {
		navMobile.style.right = '-110%'
	}
	navMobile.classList.toggle('active')
}

const setYear = () => {
	let currentYear = new Date().getFullYear()
	year.textContent = currentYear
}

setYear()

burgerBtn.addEventListener('click', handleMenu)
mobileNavLinks.forEach(el =>
	el.addEventListener('click', () => {
		navMobile.style.right = '-110%'
	})
)

contactForm.addEventListener('submit', function (event) {
	let name = document.getElementById('name').value
	let email = document.getElementById('email').value
	let msg = document.getElementById('msg').value
	const nameErrorMsg = document.querySelector('.contact__name-error')
	const emailErrorMsg = document.querySelector('.contact__email-error')
	const msgErrorMsg = document.querySelector('.contact__msg-error')

	nameErrorMsg.textContent = ''
	emailErrorMsg.textContent = ''
	msgErrorMsg.textContent = ''

	if (name == '') {
		nameErrorMsg.textContent = 'Wypełnij pole'
		event.preventDefault()
	}
	if (email == '' || !email.includes('@') || !email.includes('.')) {
		emailErrorMsg.textContent = 'Podaj prawidłowy adres e-mail'
		event.preventDefault()
	}
	if (msg == '') {
		msgErrorMsg.textContent = 'Wpisz treść wiadomości'
		event.preventDefault()
	}
})

contactForm.addEventListener('keydown', function (event) {
	if (event.key == 'Enter') {
		event.preventDefault()
	}
})

document.addEventListener('DOMContentLoaded', event => {
	if (document.location.search == '?mail_status=sent') {
		msgStatus.classList.add('success')

		setTimeout(() => {
			msgStatus.classList.remove('success')
		}, 3000)
	}
})
